// Scheme Application
export const farmerSchemeApplication = '/farmer-scheme-application/single-list'
export const farmerSchemeApplicationStore = '/farmer-scheme-application/store'
export const farmerSchemeApplicationUpdate = '/farmer-scheme-application/update'
export const schemeAppPendingPayment = '/farmer-scheme-application/pending-payment'
export const paymentypeList = '/payment-type/list'
export const documentStore = '/farmer-scheme-application/document-store'
export const getDocument = '/farmer-scheme-application/get-document'
export const destroyDocument = '/farmer-scheme-application/destroy-document'

// export const paymentList = '/payment/list'
export const paymentList = '/master-payment/get-amount'
export const paymentStore = '/payment/store'
export const paymentUpdate = '/payment/update'
export const paymentToggleStatus = '/payment/toggle-status'
export const updateStatus = '/application/update-status-as-reject'
export const applicationShow = '/scheme-application/show'

export const pumpOptApplicationSingleList = '/farmer-pump-operator-application/single-list'
export const pumpOptApplicationList = '/farmer-pump-operator-application/list'
export const pumpOptApplicationStore = '/farmer-pump-operator-application/store'
export const pumpOptApplicationUpdate = '/farmer-pump-operator-application/update'
export const pumpOptApplicationRenew = '/farmer-pump-operator-application-reniews/store'
export const pumpOptApplicationDocument = '/farmer-pump-operator-application-documents/list'
export const pumpOptApplicationDocumentStore = '/farmer-pump-operator-application-documents/store'
export const pumpOptApplicationDocumentDestroy = '/farmer-pump-operator-application-documents/destroy'
export const pumpInfoList = '/pump-information/list'
export const allPumpInfoList = '/pump-information/all-pump'
export const getPumpOptMoneyReceipt = '/farmer-pump-operator-application/money-receipt'

// farmer-water-test-request
export const waterTestingRequestList = 'farmer-water-test-application/list'
export const waterTestingRequestStore = 'farmer-water-test-application/store'
export const waterTestingRequestUpdate = 'farmer-water-test-application/update'
export const waterTestingRequestToggleStatus = 'farmer-water-test-application/toggle-status'
export const waterTestingRequestDestroy = 'farmer-water-test-application/delete'
export const getWaterTestMoneyReceipt = '/farmer-water-test-application/money-receipt'

// farmer-smart-card
const SMCPrefix = 'farmer-smart-card-app'
export const smartCardApplicationList = SMCPrefix + '/list'
export const smartCardApplicationStore = SMCPrefix + '/store'
export const smartCardApplicationUpdate = SMCPrefix + '/resubmit-update'
export const smartCardTesingPendingPayment = SMCPrefix + '/pending-payment'
export const smartCardReissuePayment = SMCPrefix + '/reissue-payment'
export const getSmartCardMoneyReceipt = SMCPrefix + '/money-receipt'

// warehouse-farmer-profile
export const wareFarmerProfileStore = '/warehouse-farmer/profile'
export const wareFarmerProfileUpdate = '/warehouse-farmer/profile'

// warehouse-farmer-complain
export const wareFarmerComplainList = '/warehouse-farmer-self-complain/list'
export const wareFarmerComplainStore = '/warehouse-farmer-self-complain/store'
export const wareFarmerComplainShowDetails = '/warehouse-farmer-self-complain/show'

// warehouse-farmer-qc-certicate
export const wareFarmerQCCertificateList = '/qc-certificate/list'
export const wareFarmerQCCertificateShowDetails = '/qc-certificate/details'

// warehouse-farmer-self-request
export const warefarmerSelfRequestList = '/farmer-self-req-entry/list'
export const warefarmerSelfRequestStore = '/farmer-self-req-entry/store'
export const farmerRequestShow = '/warehouse-service-farmer-req-entry/show'

// farmer-profile
const profilePrefix = 'farmer-basic-infos'
export const farmerProfileDetails = profilePrefix + '/details'
export const farmerProfileStore = profilePrefix + '/store'
export const farmerProfileUpdate = profilePrefix + '/update'
export const farmerInfo = profilePrefix + '/check-user'
export const farmerGetSMTCRDData = profilePrefix + '/get-smt-data'
export const basicMobileChange = profilePrefix + '/change-mobile'

// farmer-complain
const farmerComplainPrefix = 'farmer-complain'
export const farmerComplainList = farmerComplainPrefix + '/list'
export const farmerComplainStore = farmerComplainPrefix + '/store'

// external user farmer make payment
export const paymentCollectionList = '/farmer-self-payment-collection/list'
export const paymentCollectionStore = '/farmer-self-payment-collection/store'
export const complainDetails = farmerComplainPrefix + '/details'
export const complainResolvedNote = farmerComplainPrefix + '/resolved-note'
export const complainReviewNote = farmerComplainPrefix + '/review-note'

// farmer-rating-entry
export const serviceRatingStore = '/farmer-rating-infos/store'
export const serviceRatingList = '/farmer-rating-infos/list'

// warehouse-farmer-panel-ware-serivce
export const wareFarmerServiceList = '/warehouse-service/list'

//  farmer-panel-delivery-schedule
export const farDeliveryScheduleList = '/farmer-panel/delivery-schedule'

//  farmer-panel
export const operatorInfo = '/resunk/check-operator'
export const waterTesingPendingPayment = '/farmer-water-test-application/pending-payment'
export const farmerProfile = '/user/farmer-profile'
export const farDeliveryDetails = '/farmer-panel/delivery-details'

// payment
export const irrigationPayment = '/irrigation_payment/'
export const getPumpOperatorPayment = '/payment-get/pump-operator'
export const getWaterTestingPayment = '/payment-get/water-testing'
export const getSmartCardPayment = '/payment-get/smart-card'
export const getSchemeApplicaion = '/payment-get/scheme-applicaion'

// scheme-farmer-land-details
const schemeFarLandDetails = 'farmer-land-details'
export const schemeFarStore = schemeFarLandDetails + '/store'
export const schemeFarList = schemeFarLandDetails + '/list'

// schemeApplicationPayment
export const irriSchemePayment = '/irri_scheme_payment/'
export const getSchemeApplicaionFromFee = '/payment-get/scheme-applicaion-badc-from-fee'
export const badcSchemeAppFromFeePayment = '/badc-scheme-payment/from-fee'
export const getSchemeApplicaionPartFee = '/payment-get/scheme-applicaion-badc-part-fee'
export const badcSchemeAppPartFeePayment = '/badc-scheme-payment/part-fee'
export const badcSchemeAppPartFeePaymentDue = '/badc-scheme-payment/part-fee-due'
export const getSchemeApplicaionSecurityFee = '/payment-get/scheme-applicaion-badc-security-fee'
export const badcSchemeAppSecurityFeePayment = '/badc-scheme-payment/security-fee'
export const badcSchemeAppSecurityFeePaymentDue = '/badc-scheme-payment/security-fee-due'
export const getSchemeApplicaionApplicationFeeOther = '/payment-get/scheme-applicaion-other-from-fee'
export const badcSchemeAppApplicationFeeOtherPayment = '/scheme-application-payment/application-fee-other'

export const getSchemeApplicaionFormFeeBmda = '/payment-get/scheme-applicaion-bmda-form-fee'
export const bmdaSchemeAppFromFeePayment = '/bmda-scheme-payment/from-fee'

export const getSchemeApplicaionPartFeeBmda = '/payment-get/scheme-applicaion-bmda-part-fee'
export const bmdaSchemeAppPartFeePayment = '/bmda-scheme-payment/part-fee'
export const getSchMoneyReceipt = '/scheme-application/money-receipt'

// my-task-management
const taskManagementPrefix = '/task-calendar'
export const taskCalendarList = taskManagementPrefix + '/list'

// pump operator payment
export const pumpOptSecurityMoney = '/pump-opt-application/security-money'
export const pumpOptApplicationFee = '/pump-opt-application/application-fee'
export const pumpOptRenewFee = '/pump-opt-application/renew-fee'

// farmer application status
export const userStatus = '/user/farmerStatus/'
export const warehouseFarmerStatus = '/user/warehouse-farmer-status/'

// warehouse space status
export const warehouseSpaceStatus = '/warehouse-space-status/report'

//  delivery schedule list
export const deliveryScheduleList = '/warehouse-service-report/delivery-schedule-dashboard-list'

// farmer rating routes
export const farmerRatingStore = '/farmers-ratings/store'
export const farmerRatingList = '/farmers-ratings/list'
export const farmerRatingUpdate = '/farmers-ratings/update'

// farmer rating routes
export const farmerAssignTaskStore = '/my-daily-task/store'
export const farmerAssignTaskUpdate = '/my-daily-task/update'
export const farmerAssignTaskList = '/my-daily-task/list'
export const smartCardGeneratedList = 'farmer-smart-card-app/card-generated-list'

// market price start
export const warehouseMarketPriceList = '/master-market-price/list'

// for change mobile
export const changeMobileApi = '/external/user/mobile-change'
export const reportHeadingList = '/master-warehouse-report-heading/detail'
export const productStockReportApi = '/warehouse-service-report/product-stock'
export const wareSerFarmerReqEntryApi = '/warehouse-service-farmer-req-entry/'
export const mobileUpdate = '/external/user/mobile/update'

// farmer-profile
const ginnerGrowerProfilePrefix = 'ginner-grower-profile'
export const ginnerGrowerProfileDetails = ginnerGrowerProfilePrefix + '/details'
export const ginnerGrowerProfileUpdate = ginnerGrowerProfilePrefix + '/update'
export const ginnerGrowerProfileUserUpdate = '/user/update'
export const ginnerGrowerProfileProdAchList = ginnerGrowerProfilePrefix + '/production-achievement'
    // export const farmerInfo = profilePrefix + '/check-user'
    // export const farmerGetSMTCRDData = profilePrefix + '/get-smt-data'
    // export const basicMobileChange = profilePrefix + '/change-mobile'
    /**
     * The following routes ware missing
     */
export const warehouseMarketPriceStore = '/master-market-price/store'
export const warehouseMarketPriceUpdate = '/master-market-price/update'
export const categoryToggleStatus = ''
export const warehouseMarketPriceToggleStatus = ''
export const complainShowApi = ''
export const categoryList = ''

// griner-grower manage schedule
const ginnerGrowerManageSchedule = '/ginner-grower-profile/'
export const ggManageScheduleList = ginnerGrowerManageSchedule + 'manage-schedule/list'
export const ggManageScheduleStore = ginnerGrowerManageSchedule + 'manage-schedule/store'
export const ggManageScheduleUpdate = ginnerGrowerManageSchedule + 'manage-schedule/update'
export const ginnerGrowerHattNotification = ginnerGrowerManageSchedule + 'hatt-notification'

// divisional-head panel routes
const divisionalHead = '/e-pusti/divisional-head/'
export const campaignReqList = divisionalHead + 'campaign-request/list'
// divisional-head campaign material routes
export const campaignMatList = divisionalHead + 'campaign-material/list'
export const campaignMatStore = divisionalHead + 'campaign-material/store'
export const campaignMatUpdate = divisionalHead + 'campaign-material/update'
export const campaignMatDetailsStore = divisionalHead + 'campaign-material-details/store'
export const campaignMatDetailsDestroy = divisionalHead + 'campaign-material-details/destroy'
export const campaignMatDetailsList = divisionalHead + 'campaign-material-details/list'
// divisional-head campaign information routes
export const campaignInfoList = divisionalHead + 'campaign-information/list'
export const campaignInfoStore = divisionalHead + 'campaign-information/store'
export const campaignInfoUpdate = divisionalHead + 'campaign-information/update'
export const campaignInfoDestroy = divisionalHead + 'campaign-information/destroy'
// divisional-head campaign attendance routes
export const campaignAttenList = divisionalHead + 'campaign-attendance/list'
export const campaignAttenStore = divisionalHead + 'campaign-attendance/store'
export const campaignAttenUpdate = divisionalHead + 'campaign-attendance/update'
export const campaignAttenDestroy = divisionalHead + 'campaign-attendance/destroy'
// divisional-head campaign feedbacj routes
export const CampaignFeedbackList = divisionalHead + 'campaign-feedback/list'
export const CampaignFeedbackStore = divisionalHead + 'campaign-feedback/store'
export const CampaignFeedbackUpdate = divisionalHead + 'campaign-feedback/update'
export const CampaignFeedbackDestroy = divisionalHead + 'campaign-feedback/destroy'
// divisional-head campaign Dashboard
export const CampaigndashboardList = 'e-pusti/divisional-head/campaign-dashboard/list'
// pump otp get details route
export const pumpOptApplicationDetailsList = 'farmer-pump-operator-application/application-details'

// dealer panel profile data
export const profileListData = 'dealer-panel/profile'
export const profileListDataStore = 'dealer-panel/profile-store'

// dealer allocation api
export const dealerAllocationList = '/dealer-panel/fertilizer-allotment'

// griner-grower manage schedule
const dealerPanelRequisition = 'dealer-panel/requisitions/'
export const requisitionList = dealerPanelRequisition + 'list'
export const requisitionStore = dealerPanelRequisition + 'store'
export const requisitionUpdate = dealerPanelRequisition + 'update'
export const requisitionStatus = dealerPanelRequisition + 'toggle-status'
export const requisitionFinalSave = dealerPanelRequisition + 'final-save'
export const dealerPaneldashboard = 'dealer-panel/dashboard'

// griner-grower manage schedule
const saleEntryBasePath = 'dealer-panel/sells/'
export const saleEntryList = saleEntryBasePath + 'list'
export const saleEntryStatus = saleEntryBasePath + 'toggle-status'
export const saleEntryStore = saleEntryBasePath + 'store'
export const saleEntryUpdate = saleEntryBasePath + 'update'
export const saleEntryFinalSave = saleEntryBasePath + 'final-save'
export const allocationStockInfo = 'dealer-panel/allocation-stock'

// Fertilizer Requisition Reports Route
export const fertilizerRequisitionReport = '/dealer-panel/reports/fertilizer-requisition-report'
export const fertilizerSalesReport = '/dealer-panel/reports/fertilizer-sales-report'
export const reportHeadingListfertilizer = '/fertilizer/config/report-heading/detail'
// Fertilizer Allotment Reports Route
export const fertilizerAllotmentReport = '/dealer-panel/reports/fertilizer-allotment-report'

// Application Form Grant Panel
const grantApplicationForm = '/barc/applicant-panel/application-form/'

export const grantApplicantDetails = '/external-user/application/applicant-details'

export const appFinalSave = grantApplicationForm + 'final-save'
export const genInfoStore = grantApplicationForm + 'general-info/store'

// address in Grant Panel
export const addressStore = grantApplicationForm + 'address-info/store'

// annexure1 in Grant Panel
export const annexure1Store = grantApplicationForm + 'annexure-one-info/store'

// annexure2 in Grant Panel
export const EmployeeRecordStore = grantApplicationForm + 'employee-record/store'
export const EmployeeRecordDestroy = grantApplicationForm + 'employee-record/destroy'
export const PublicationInfoStore = grantApplicationForm + 'publication-info/store'
export const PublicationInfoDestroy = grantApplicationForm + 'publication-info/destroy'
export const RelevantFactStore = grantApplicationForm + 'relevant-fact/store'

// annexure3 in Grant Panel
export const annexure3Store = grantApplicationForm + 'annexure-three-info/store'
export const annexure3Delete = grantApplicationForm + 'annexure-three-info/delete'

// applicationDetails in Grant Panel
export const applicationDetailsShow = grantApplicationForm + 'show'

// Circular List
export const checkExistProfile = '/barc/applicant-panel/applicant/exist-profile-check'
export const applicantProfileUpdate = '/barc/applicant-panel/applicant/profile-update'
export const circularList = '/barc/applicant-panel/circular/list'
export const checkUniversityCourse = '/barc/applicant-panel/circular/check/university/course'

// applicationDetails in Grant Panel
export const grantApplicationList = grantApplicationForm + 'list'

// language in Grant Panel
export const languageStore = grantApplicationForm + 'language-info/store'

// academic in Grant Panel
export const academicStore = grantApplicationForm + 'academic-info/store'
export const academicDestroy = grantApplicationForm + 'academic-info/destroy'

// training in Grant Panel
export const trainingStore = grantApplicationForm + 'traning-info/store'
export const trainingDestroy = grantApplicationForm + 'training-info/destroy'

// seminar in Grant Panel
export const seminarStore = grantApplicationForm + 'seminar-info/store'
export const seminarDestroy = grantApplicationForm + 'seminar-info/destroy'

// qualification in Grant Panel
export const qualificationStore = grantApplicationForm + 'qualification-info/store'

// ===========Contact-us & musume information===============
export const museumInfoByOrgApi = '/museum-info/museum/by-org'

// Virtual krishibid
export const krishibidQaListApi = '/configuration/virtual-krishibid/list'

// visit request
export const dateWiseSlotApi = '/public-panel/visit-request/date-wise-slot'
export const phyVisitStoreApi = '/public-panel/visit-request/store-physical-visit'
export const phyVisitListApi = '/public-panel/visit-request/physical-visit-list'
export const phyVisitUpdateApi = '/public-panel/visit-request/update-physical-visit'
export const phyVisitRatingApi = '/public-panel/visit-request/visit-rating'
// Fund Request for grant panel
const fundReq = '/external-user/fund-request/'
export const fundReqList = fundReq + 'list'
export const fundReqStore = fundReq + 'store'
export const fundReqUpdate = fundReq + 'update'
export const fundReqShow = fundReq + 'show'
export const getAvailableFund = fundReq + 'available-fund'
